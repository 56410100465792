import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { gsap } from "../plugins/gsap"
import { ScrollTrigger } from "../plugins/gsap/ScrollTrigger"

import Layout from "../components/layout"
import Seo from "../components/seo"
import PinkGradientGhostButton from "../components/ui/pink-gradient-ghost-button"
import { Container } from "../components/ui/grid"
import { GatsbyImage } from "gatsby-plugin-image"
import { BLOCKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

gsap.registerPlugin(ScrollTrigger);

const options = {
  renderNode: {
    [BLOCKS.HEADING_1]: (_node, children) => {
      return <h1 className="xl:text-6xl m-0" style={{whiteSpace: 'pre'}}>{children}</h1>;
    },
    [BLOCKS.HEADING_2]: (_node, children) => {
      return <h2 className="xl:text-3xl mb-2">{children}</h2>;
    },
    [BLOCKS.PARAGRAPH]: (_node, children) => {
      return <p className="xl:text-xl m-0">{children}</p>;
    },
  },
};

const MobileTesting = ({ location }) => {
  const { data } = useStaticQuery(
    graphql`
      query {
        data: contentfulPageMobileTesting{
          title
          description
          featuredImage {
            gatsbyImageData
            resize(width: 512, quality: 70) {
              src
            }
          }
          heroImage {
            gatsbyImageData(
              placeholder: NONE
            )
            title
          }
          heroContent {
            raw
          }
          heroButtons {
            title
            url
          }
          firstSectionTitle
          firstSectionText {
            raw
          }
          firstSectionImage {
            gatsbyImageData(
              placeholder: NONE
            )
            title
          }
          secondSectionTitle
          secondSectionText {
            raw
          }
          secondSectionImage {
            gatsbyImageData(
              placeholder: BLURRED
            )
            title
          }
          iconSectionPreTitle 
          iconSectionTitle
          iconSectionIcons {
            title
            text
            icon {
              gatsbyImageData(
                placeholder: BLURRED
              )
              title
            }
          }
        }
      }
    `
  );

  const title = data?.title || "";
  const description = data?.description || "";
  const thumbnail = data?.featuredImage?.resize?.src || "";
  const heroImage = data?.heroImage;
  const heroContent = data?.heroContent;
  const heroButtons = data?.heroButtons;
  const firstSectionTitle = data?.firstSectionTitle || "";
  const firstSectionImage = data?.firstSectionImage;
  const firstSectionText = data?.firstSectionText;
  const secondSectionTitle = data?.secondSectionTitle || "";
  const secondSectionImage = data?.secondSectionImage;
  const secondSectionText = data?.secondSectionText;
  const IconSectionPreTitle = data?.iconSectionPreTitle;
  const IconSectionTitle = data?.iconSectionTitle;
  const IconSectionIcons = data?.iconSectionIcons;

  return (
    <Layout
      location={location}
    >
      <link rel="preconnect" href="https://fonts.googleapis.com" />
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      <link href="https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap" rel="stylesheet" />

      <Seo
        title={title}
        description={description}
        image={thumbnail}
        url={location.href}
      />

      <div className='mobile-testing-wrapper'>

        <header>
          <Container className='col-2'>
            <div className="text-and-btns-section">
              {heroContent && renderRichText(heroContent, options)}

              <div className="btn-section">
                {heroButtons.map(({ title, url }, i) => (
                  <PinkGradientGhostButton title={title} href={url} key={i}/>
                ))}
              </div>
            </div>

            <GatsbyImage
              image={heroImage?.gatsbyImageData}
              alt={heroImage?.title}
            />
          </Container>
        </header>

        <Container className='col-3-to-2'>
          <div className="text-on-img">
            <h1 className="xl:text-6xl">{firstSectionTitle}</h1>
            <GatsbyImage
              image={firstSectionImage?.gatsbyImageData}
              alt={firstSectionImage?.title}
            />
          </div>

          <div className="text-section">
            {firstSectionText && renderRichText(firstSectionText, options)}
          </div>
        </Container>

        <Container>
          <div className="icons-title">
            <h3 className="xl:text-l">{IconSectionPreTitle}</h3>
            <h1 className="xl:text-5xl">{IconSectionTitle}</h1>
          </div>
          <div className="icons-container">
            {IconSectionIcons.map((icon) => (
              <IconAndText
                title={icon.title}
                text={icon.text}
                image={icon.icon.gatsbyImageData}
                key={icon.title}
              />
            ))}
          </div>
        </Container>

        <Container className='col-2-to-3'>
          <div className="text-section">
            <h1 className="xl:text-6xl">{secondSectionTitle}</h1>
            {secondSectionText && renderRichText(secondSectionText, options)}
          </div>

          <GatsbyImage
            image={secondSectionImage?.gatsbyImageData}
            alt={secondSectionImage?.title}
          />
        </Container>

      </div>
    </Layout>
  )
}

const IconAndText = ({ title, text, image }) => (
  <div className="icon">
    <GatsbyImage
      image={image}
      alt={title}
    />
    <h3>{title}</h3>
    <p>{text}</p>
  </div>
);

export default MobileTesting
