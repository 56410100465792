import React from "react";

const PinkGradientGhostButton = (props) => {
    return props.href ? (
        <a className="gradient-pink-ghost-btn" href={props.href}>{props.title}</a>
    ) : (
        <button className="gradient-pink-ghost-btn" onClick={props.onClick}>{props.title}</button>
    );
}

export default PinkGradientGhostButton;